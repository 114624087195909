<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('withdraw2[0]')"
            left-arrow @click-right="onClickRight"
            @click-left="$router.go(-1)"
    ><van-icon name="./skin/task01.png" slot="right" />
    </van-nav-bar>
      <div class="box">
        <div>
          <van-field
                  v-model="BankInfo.name"
                  :label="$t('bindAccount.fields[0]')"
                  :placeholder="$t('bindAccount.placeholder[0]')"
          />
          <van-field
                  v-model="BankInfo.phone"
                  :label="$t('bindAccount.fields[1]')"
                  :placeholder="$t('bindAccount.placeholder[1]')"
          />
          <van-field
                  v-model="BankInfo.card_no"
                  :label="$t('bindAccount.fields[2]')"
                  :placeholder="$t('bindAccount.placeholder[2]')"
          />
          <van-field
                  v-model="BankInfo.bank_name"
                  :label="$t('bindAccount.fields[3]')"
                  :placeholder="$t('bindAccount.placeholder[3]')"
          />
          <van-field v-model="BankInfo.money"
                     :label="$t('wallet.label[1]')"
                     :placeholder="$t('wallet.placeholder[1]')"
          />
        </div>
      </div>
        <div style="text-align: center;margin-top: 20px">
          <van-button @click="submitDraw">{{$t('recharge.default[4]')}}</van-button>
        </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import axios from "axios";
import { Toast } from "vant";
import config from "@/config";

export default {
  name: "mixRecharge",
  components: {},
  props: ["walletType"],
  data() {
    return {
      showPicker: false,
      columns:[],
      BankInfo:{},
    };
  },
  computed: {

  },
  watch: {},
  created() {

    this.$Model.GetBankCardList(data=>{
      if (data.code==1&&data['data'].length>0){
            this.BankInfo = data['data'][0];
          }
        }
    );
  },
  mounted() {
    // this.getQRUrl();
  },
  activated() {},
  destroyed() {
  },
  methods: {
    onClickRight(){
      this.$router.push("/user/withdrawlist");
    },
    submitDraw() {
      let postData = this.BankInfo;
      debugger
      this.$Model.Draw(postData, (data) => {
        // this.isSubmit = false;
        let msg = this.$t('taskOrder2[3]');
        switch (data.code) {
          case 1:
            msg = this.$t('common3[0]');
            break;
          case -1:
            msg = this.$t('withdraw2[6]');
            break;
          default:
            msg = data.code_dec;
            break;
        }
        this.$Dialog.Toast(msg);
        if (data.code==1){
          this.$router.push("/user");
        }
      });
    },
  },
};
</script>

<style scoped>

.PageBox >>> .van-nav-bar__title{
  color:#FFFFFF;
  font-size: 18px;
}
.PageBox >>> .van-nav-bar .van-nav-bar__arrow {
  font-size: 20px;
  color:#FFFFFF;
}

  .PageBox {
    overflow: auto;
    padding-top: 60px;
    background: url(~@/assets/img/new/fund_bg.png) no-repeat;
    background-size:  100% auto;
    color: #333;
    background-color: #F5F8FF;
  }

  .PageBox .box {
    width: 95%;
    padding: 0px 10px;
    font-size: 19px;
    margin-top: 15px;
    border-radius: 10px;
    margin-left: 10px;
    background-color: var(--panel_color);
  }


  p{
    width: 80px;
  }


  .btn {
    width: 95%;
    padding: 10px 50px;
    border-radius: 5px;
    background-color: #5652FD;
    color: #fff;
    font-size: 20px;
    text-align: center;
    margin: 15px 10px 30px;
  }
</style>
